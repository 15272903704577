import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import axiosRetry from 'axios-retry';
import z from 'zod';

const directusApiUri = process.env.NEXT_PUBLIC_DIRECTUS_API_URL;
const apiUri = process.env.NEXT_PUBLIC_API_URL;
const assetsUri =
  process.env.NEXT_PUBLIC_ASSETS_URL || `${directusApiUri}/assets`;
const mintScaleUri = `${process.env.NEXT_PUBLIC_MINTSCALE_URL}/api`;

const directusService =
  typeof window === 'undefined'
    ? setupCache(
        axios.create({
          baseURL: directusApiUri,
        }),
        {
          headerInterpreter: () => 300, // 5 minutes
        },
      )
    : axios.create({ baseURL: directusApiUri });

const apiService = axios.create({
  baseURL: apiUri,
  headers: { 'Access-Control-Allow-Origin': '*' },
});

const duoportaApiService = axios.create({
  baseURL: apiUri,
  headers: { 'Access-Control-Allow-Origin': '*' },
});

axiosRetry(apiService, { retries: 3 })

const mintscaleService = axios.create({
  baseURL: mintScaleUri,
  headers: { 'Access-Control-Allow-Origin': '*' },
});

directusService.interceptors.response.use(
  (response) => {
    if (!response.data && !response) {
      return Promise.reject(response);
    }
    return response.data ? response.data : response;
  },

  (error) => {
    if (typeof window === 'undefined') {
      console.error(error);
    }
    return Promise.reject(error);
  },
);

apiService.interceptors.response.use(
  (response) => {
    return response.data ? response.data : response;
  },
  (error) => {
    if (typeof window === 'undefined') {
      console.error(error);
    }
    return Promise.reject(error);
  },
);

duoportaApiService.interceptors.response.use(
  (response) => {
    return response.data ? response.data : response;
  },
  (error) => {
    if (typeof window === 'undefined') {
      console.error(error);
    }
    return Promise.reject(error);
  },
);

const wrap = <T extends Array<any>, U>(fn: (...args: T) => U) => {
  return (...args: T): U => fn(...args);
};

const limiter = { wrap };

// get zod object keys recursively
const zodKeys = <T extends z.ZodTypeAny>(schema: T): string[] => {
  // make sure schema is not null or undefined
  if (schema === null || schema === undefined) return [];
  // check if schema is nullable or optional
  if (schema instanceof z.ZodNullable || schema instanceof z.ZodOptional)
    return zodKeys(schema.unwrap());
  // check if schema is an array
  if (schema instanceof z.ZodArray) return zodKeys(schema.element);
  // check if schema is an object
  if (schema instanceof z.ZodObject) {
    // get key/value pairs from schema
    const entries = Object.entries(schema.shape);
    // loop through key/value pairs

    return entries.flatMap(([key, value]) => {
      // get nested keys
      const nested =
        value instanceof z.ZodType
          ? zodKeys(value).map((subKey) => `${key}.${subKey}`)
          : [];
      // return nested keys

      return nested.length ? nested : key;
    });
  }
  // return empty array
  return [];
};

export {
  directusService,
  apiService,
  duoportaApiService,
  directusApiUri,
  assetsUri,
  limiter,
  mintscaleService,
  zodKeys,
};

'use client';
import { Box, Text } from '@chakra-ui/react';
import Link from 'next/link';

import { ReactComponent as FacebookIcon } from '@/app/_icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@/app/_icons/instagram.svg';
import { ReactComponent as LinkedinIcon } from '@/app/_icons/linkedin.svg';
import { ReactComponent as TwitterIcon } from '@/app/_icons/new-twitter.svg';
import { ReactComponent as TiktokIcon } from '@/app/_icons/tikTok.svg';
import { ReactComponent as YoutubeIcon } from '@/app/_icons/youtube.svg';
import {
  kiaFacebookUrl,
  kiaInstagramUrl,
  kiaLinkedinUrl,
  kiaTikTokUrl,
  kiaTwitterUrl,
  kiaYouTubeUrl,
} from '@/constants/links/links.constants';
import { useOpenFloatingCTA } from '@/hooks/floating-cta.hook';

import ButtonCustom from '../button/ButtonCustom';

import Styles from './CTA.module.scss';

export default function Component() {
  const openLeadForm = useOpenFloatingCTA();

  const handleClick = () => {
    openLeadForm('signup', 'Signup');
  };

  return (
    <div className={Styles.footerTop}>
      <div className={Styles.footerCta}>
        <div className={Styles.headingElement}>
          <Text
            as="p"
            className={Styles.titleMd}
            fontWeight="bold"
            color="white"
          >
            Don’t miss a beat
          </Text>
          <Text className={Styles.titleText}>
            Sign up to get the latest updates
          </Text>
        </div>
        <ButtonCustom
          className="btnOutlineWhite"
          onClick={handleClick}
          moduleClass={Styles.ctaBtn}
        >
          Stay updated
        </ButtonCustom>
      </div>
      <div className={Styles.footerSocialWrapper}>
        <div className={Styles.footerSocialLinkWrap}>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaFacebookUrl}
              target="_blank"
              rel="noopener noreferrer"
              key={'kiaFacebookUrl'}
              data-cy="facebook-icon"
              className={Styles.socialLink}
              aria-label="Follow us on Facebook"
            >
              <FacebookIcon
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaTwitterUrl}
              target="_blank"
              rel="noopener noreferrer"
              key={'kiaTwitterUrl'}
              data-cy="twitter-icon"
              className={Styles.socialLink}
              aria-label="Follow us on Twitter"
            >
              <TwitterIcon
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaLinkedinUrl}
              target="_blank"
              rel="noopener noreferrer"
              key={'kiaLinkedInUrl'}
              data-cy="linkedIn-icon"
              className={Styles.socialLink}
              aria-label="Connect with us on LinkedIn"
            >
              <LinkedinIcon
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaInstagramUrl}
              target="_blank"
              rel="noopener noreferrer"
              key={'kiaInstagramUrl'}
              className={Styles.socialLink}
              aria-label="Follow us on Instagram"
            >
              <InstagramIcon
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaYouTubeUrl}
              target="_blank"
              rel="noopener noreferrer"
              key={'kiaYouTubeUrl'}
              data-cy="youtube-icon"
              className={Styles.socialLink}
              aria-label="Subscribe to our YouTube channel"
            >
              <YoutubeIcon
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
          <Box className={Styles.footerSocialLink}>
            <Link
              href={kiaTikTokUrl}
              target="_blank"
              rel="noopener noreferrer"
              key={'kiaTikTokUrl'}
              data-cy="tikTok-icon"
              className={Styles.socialLink}
              aria-label="Follow us on TikTok"
            >
              <TiktokIcon
                width={24}
                height={24}
                className={Styles.socialLinkLogo}
              />
            </Link>
          </Box>
        </div>
      </div>
    </div>
  );
}
